import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_consequences'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how"
    }}>{`How?`}</h1>
    <p><strong parentName="p">{`Set aside some time to talk`}</strong>{` with your teenager about your expectations for their behaviour.`}</p>
    <p>{`With your teenager, decide on realistic `}<strong parentName="p">{`consequences`}</strong>{` for when rules are broken. These consequences should be unpleasant enough to be a deterrent, but not so harsh that they may damage your relationship with your teenager.`}</p>
    <ButtonDialog id="consequences" linkText="Suggestions of consequences" mdxType="ButtonDialog">
  <p>
    <b>Consequences that match the behaviour</b> e.g. if your teenager is home 15 minutes later than the agreed time,
    then they have to come home 15 minutes earlier next time.
  </p>
  <p>
    <b>Losing privileges</b> such as being driven to places for a set period of time.
  </p>
  <p>
    <b>Banning them from using electronic devices or watching TV</b> e.g. no gaming for 2 days.
  </p>
  <p>
    <b>Extra household chores for a specific timeframe</b> e.g. wash the dinner dishes for 3 days.
  </p>
  <p>
    <b>Being "grounded"</b> - not being allowed to go out for anything but school and family activities for a specific
    period of time.
  </p>
    </ButtonDialog>
    <p>{`Make sure your teenager clearly `}<strong parentName="p">{`understands`}</strong>{` what the rules are and the `}<strong parentName="p">{`reasons`}</strong>{` behind them.`}</p>
    <p>{`It is useful to `}<strong parentName="p">{`write down a set of rules`}</strong>{`. Writing them down makes them clear, and can also prevent arguments about what is or isn’t allowed. For rules that are specific to your teenager, it may be a good idea to keep the rules somewhere a little private that’s still close at hand for when you need to refer to them, instead of making the rules public, such as by sticking them on the fridge.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m3/15.svg" alt="Doing Chores" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      